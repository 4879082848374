const initialState = {
    openNewsletter: false,
    whiteSidebar: false,
    news: []
}

const TOGGLE_NEWSLETTER = 'TOGGLE_NEWSLETTER'
const TOGGLE_WHITESIDEBAR = 'TOGGLE_WHITESIDEBAR'
const SET_NEWS = 'SET_NEWS'

export const toggleNewsletter = openNewsletter => ({
    type: TOGGLE_NEWSLETTER, openNewsletter
})

export const toggleWhiteSidebar = whiteSidebar => ({
    type: TOGGLE_WHITESIDEBAR, whiteSidebar
})

export const setNews = news => ({
    type: SET_NEWS, news
})

const App =  (state = initialState, action) => {
    switch (action.type) {
        case TOGGLE_NEWSLETTER:
            return { ...state, openNewsletter: action.openNewsletter }
        case TOGGLE_WHITESIDEBAR:
            return { ...state, whiteSidebar: action.whiteSidebar }
        case SET_NEWS:
            return { ...state, news: action.news }
        default:
            return state
    }
}
export default App